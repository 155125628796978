import { InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

function UISelect(props) {
    const { label, name, children, value, onChange } = props
    return (
        <>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...props}
                defaultValue={""}
                label={label}
                name={name}
                value={value}
                MenuProps={{
                    sx: {
                        "&& .Mui-selected": {
                            backgroundColor: "#192036"
                        },
                        "&& .Mui-selected:hover": {
                            backgroundColor: "#192036"
                        },
                        "&& .MuiMenu-list": {
                            backgroundColor: "#111827"
                        }
                    }
                }}
                onChange={onChange}
                sx={{ color: "text.primary", backgroundColor: "#111827" }}
            >
                <MenuItem sx={{
                    color: "text.primary", backgroundColor: "#111827", '&:hover': {
                        backgroundColor: value === "" ? "#192036" : 'rgb(0,0,0)'
                    },
                }} selected value={""}>{label}</MenuItem>
                {children}
            </Select>
        </>
    )
}

export default UISelect

import Typography from "@mui/material/Typography"
import React from 'react'
import Grid from "@mui/material/Grid"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"
import { Link } from "react-router-dom";
const HelpBlock = ({ Icon, title, btnTxt, bg, color, link }) => {
    return (
        <Grid
            item
            lg={4}
            xs={12}

        >
            <Box sx={{
                widht: "100%",
                backgroundColor: "neutral.700",
                px: 3,
                py: 2,
                borderRadius: 1
            }}>
                <Box display="flex" alignItems="center" flexDirection="column" item>
                    <Avatar
                        sx={{
                            height: 56,
                            width: 56,
                            color: "#fff",
                            backgroundColor: bg
                        }}
                    >{
                            <Icon />
                        }
                    </Avatar>
                    <Typography
                        color="neutral.600"
                        gutterBottom
                        variant="overline"
                        mt={2}
                    >
                        {title}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        <Link to={link}>
                            <Button color={color} variant='contained'>
                                {btnTxt}
                            </Button>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Grid>
    )
}

export default HelpBlock
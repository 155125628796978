import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from "@mui/material/Typography"
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import MainCover from '../../Components/MainCover'
import { UISelect } from '../../Components/UI'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import firebase from "../../firebase"
import showUserDetail from '../../util/common/showUserDetails'

const AddProduct = () => {
    const [{ category, crops }] = useDataLayerValue()
    const [formData, setFormData] = useState({
        category: "",
        organic: "no"
    })
    const [user, setUser] = useState({
        userType: "corporate"
    })
    const [itemDetail, setItemDetail] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const navigate = useHistory()
    
    let value, name;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const handleCheckbox = (event) => {
        name = event.target.name;
        value = event.target.value;
        if (formData[name] === "yes") {
            setFormData({ ...formData, [name]: "no" });
        }
        else {
            setFormData({ ...formData, [name]: "yes" });
        }
    }
    const addItem = (e) => {
        e.preventDefault()
        const TIME_STEMP = Date.now()
        var itemLocalDetail = itemDetail
        const imgExtRemover = () => {
            var fileName = itemDetail.item_image.name;
            return fileName.split('.').pop()
        }
        firebase.storage().ref("items/").child(`${TIME_STEMP}.${imgExtRemover()}`).put(itemLocalDetail.item_image).on("state_changed",
            (snapshot) => {
                setIsUploading(true)
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress)
            },
            (error) => {

            },
            (event) => {
                firebase.storage().ref('/items').child(`${TIME_STEMP}.${imgExtRemover()}`).getDownloadURL().then((url) => {
                    delete itemLocalDetail.item_image
                    var sellerName = ""
                    if (user.userType === "corporate") {
                        sellerName = user.corporateData.name
                    }
                    else {
                        sellerName = user.name
                    }
                    firebase.database().ref('items').push({
                        ...formData,
                        block: user.block ? user.block : null,
                        district: user.district,
                        imgUrl: url,
                        sellerName: sellerName,
                        sellerUID: user.userId,
                        state: user.state,
                        timeStamp: TIME_STEMP,
                        userType: user.userType,
                    }).then(() => {
                        Swal.fire("Item Registerd Successfully!", '', 'success').then(() => {
                            navigate.push("/help-desk")
                        })
                    })
                })
            }
        )
    }
    return (
        <MainCover>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUploading}
            >
                <Box sx={{ width: '60%' }}>
                    <LinearProgressWithLabel value={uploadProgress} />
                </Box>
            </Backdrop>
            <div className="container smart-card">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{ m: 1, color: 'text.primary', }}
                        variant="h4"
                    >
                        Add product to store
                    </Typography>
                </Box>
                <form onSubmit={addItem}>
                    <Grid sx={{ my: 2 }} gap={4} container>
                        <Grid sm={8}>
                            <TextField fullWidth required onChange={handleFormChanges} type="text" name="userId" label="User ID(UID)" />
                        </Grid>
                        <Grid sm={3}>
                            <Button onClick={showUserDetail({ userId: formData.userId, setUser })} fullWidth sx={{ height: "100%" }} color="success" variant='contained'>
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                    <FormControl sx={{ my: 2 }} fullWidth>
                        <UISelect
                            onChange={handleFormChanges}
                            name="category"
                            id=""
                            label="Select Category"
                            value={formData.category}
                        >
                            {
                                category.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.categorieName}>{item.categorieName}</MenuItem>
                                    )
                                })
                            }

                        </UISelect>
                    </FormControl>
                    <FormControl sx={{ my: 2 }} fullWidth>
                        <UISelect
                            label="Select Item Name"
                            value={formData.name}
                            name="name"
                            disabled={itemDetail.category === "" ? true : false}
                            onChange={handleFormChanges}
                        >
                            {
                                crops.map((item, index) => {
                                    if (item.category === formData.category) {
                                        return <MenuItem value={item.cropName}>{item.cropName}</MenuItem>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </UISelect>
                    </FormControl>
                    <div className="form-group my-4">

                        <TextField
                            onChange={handleFormChanges}
                            label="Variety - Make"
                            name="variety"
                            type="text"
                            fullWidth
                        />
                    </div>
                    <div className="my-4">
                        <textarea onChange={handleFormChanges}
                            name="description" fullWidth type="text" placeholder="Description" />
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 my-4">
                            <TextField onChange={handleFormChanges}
                                required
                                fullWidth
                                name="price"
                                type="number"
                                label="Price"
                                placeholder="Price - मूल्य" />
                        </div>
                        <FormControl className="col-lg-6 my-4">
                            <UISelect label="Unit" required name="unit" onChange={handleFormChanges} >
                                <MenuItem value="kg">per kg</MenuItem>
                                <MenuItem value="liter">per liter(L)</MenuItem>
                                <MenuItem value="quintal">per quintal(100 KG)</MenuItem>
                                <MenuItem value="100grams">per 100grams</MenuItem>
                                <MenuItem value="250grams">per 250grams</MenuItem>
                                <MenuItem value="500grams">per 500grams</MenuItem>
                                <MenuItem value="dozen">per dozen(12 Pieces)</MenuItem>
                                <MenuItem value="piece">per piece</MenuItem>
                            </UISelect>
                        </FormControl>
                    </div>
                    <div className="form-group my-4">
                        <label className="form-label"></label>
                        <TextField fullWidth onChange={handleFormChanges}
                            required
                            name="quantity" type="number" label="Available Quantity" />
                    </div>

                    <div className="d-flex aligin-items-center my-2">
                        <input type="checkbox" onChange={handleCheckbox} className="form-check-input" id="organic_yes" name="organic" value="yes" />
                        <label for="organic_yes">
                            <Typography
                                sx={{ ml: 1, color: 'text.light', }}
                                variant="h6"
                            >
                                Is this product oraganic?
                            </Typography>
                        </label>
                    </div>
                    {
                        formData.organic === "yes" &&
                        <div className="form-group my-4">

                            <TextField
                                fullWidth
                                label="Your Organic Certificate No"
                                onChange={handleFormChanges}
                                required
                                name="certificateNo"
                                type="number" />
                        </div>
                    }
                    <div className="form-group my-4">
                        <label htmlFor="coverImg_file">
                            <Box style={{ padding: "20px 40px", backgroundColor: "#111827", cursor: "pointer", borderRadius: 5 }}>
                                <Grid container columnGap={4} >
                                    <Grid sm={2} display="flex" justifyContent={"center"} sd={2}>
                                        <img className="img-fluid" style={{ borderRadius: 10 }} src={'/images/upload_image.png'} alt="" />
                                    </Grid>
                                    <Grid sm={4} sd={4}>
                                        <Typography sx={{ color: 'text.light' }} variant="h6" component="div" gutterBottom>
                                            Select Cover Image
                                        </Typography>
                                        <Typography sx={{ color: 'text.light' }} variant="subtitle2" component="div" gutterBottom>
                                            Click to add images
                                        </Typography>
                                    </Grid>
                                    {
                                        itemDetail.item_image && <Grid sd={4} sm={4}>
                                            <img className="img-fluid" src={URL.createObjectURL(itemDetail.item_image)} alt="" />
                                        </Grid>
                                    }

                                </Grid>
                            </Box>
                        </label>
                        <input onChange={(e) => { setItemDetail({ ...itemDetail, item_image: e.target.files[0] }) }}
                            required
                            id="coverImg_file"
                            name="item_image" type="file" className="d-none" />
                    </div>


                    <button className="btn  btn-success" type="submit"  >Add Item - फसल डालें<i className="fa fa-upload"></i></button>

                </form>
            </div>
        </MainCover >
    )
}
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default AddProduct
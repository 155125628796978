class Filter {
    constructor(list = []) {
    this.list = list
}

byState(state){
    var newList = this.list.filter(item => item.state == state)
    return newList
}
byDistrict(district){
    var newList = this.list.filter(item => item.district == district)
    return newList
}
byBlock(block){
    var newList = this.list.filter(item => item.block == block)
    return newList
}
    
}
export default Filter
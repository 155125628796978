import { TextField, Box, Container, InputLabel, Select, MenuItem, Button, FormControl } from "@mui/material"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import SecondMenu from "../../Components/SecondMenu"
import SlideMenu from "../../Components/SlideMenu"
import { useDataLayerValue } from "../../DataLayer/DataLayer"
import firebase from "../../firebase"
/* eslint-disable */
function AddCrops() {
    const [{ category }] = useDataLayerValue()
    const [itemDetail, setItemDetail] = useState({
    })

    let name, value;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setItemDetail({ ...itemDetail, [name]: value });
    }
    const imgExtRemover = () => {
        var fileName = itemDetail.item_image.name;
        return fileName.split('.').pop()
    }

    const addCrop = (e) => {
        e.preventDefault()
        console.log(itemDetail.item_image)
        var TIME_STEMP = Date.now()
        firebase.database().ref('crops/').push(itemDetail).then(() => {
            Swal.fire("Item Crop added Successfully", '', 'success')
        })
    }
    useEffect(() => {
        const loadData = () => {
            firebase.database().ref('categories/').on('value', (snapshot) => {
                const snapVal = snapshot.val();
                const fatched = [];
                for (let id in snapVal) {
                    fatched.push({ id, ...snapVal[id] })

                    setData({
                        ...data,
                        cateList: fatched
                    })
                }


            })
        }
        loadData()
    }, [])

    return (
        <>
            <SlideMenu />
            <div className="main-display">
                <div className="main-child">
                    <SecondMenu title="Add Item" url="#" />
                    <Box
                        sx={{
                            bgcolor: 'background.default',
                            // width: "100%"
                            display: "flex",
                            flex: 1,
                        }}
                        container
                        className="main-display edit-categories"
                    >
                        <Container>
                            <form action="" onSubmit={addCrop}>
                                <div className="form-group my-4">
                                    <TextField varient={"outlined"} onChange={handleFormChanges}
                                        required
                                        fullWidth
                                        label="Crop Name / Item Name"
                                        name="cropName" type="text" />
                                </div>


                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={""}
                                        label="Category"
                                        name="category"
                                        value={itemDetail.category}
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#192036"
                                                },
                                                "&& .Mui-selected:hover": {
                                                    backgroundColor: "#192036"
                                                },
                                                "&& .MuiMenu-list": {
                                                    backgroundColor: "#111827"
                                                }
                                            }
                                        }}
                                        onChange={handleFormChanges}
                                        sx={{ color: "text.primary", backgroundColor: "#111827" }}
                                    >
                                        <MenuItem sx={{
                                            color: "text.primary", backgroundColor: "#111827", '&:hover': {
                                                backgroundColor: itemDetail.cateogry === "" ? "#192036" : 'rgb(0,0,0)'
                                            },
                                        }} selected value={""}>Category</MenuItem>
                                        {
                                            category.map((item, index) => {
                                                return (
                                                    <MenuItem sx={{
                                                        color: "text.primary", backgroundColor: "#111827", '&:hover': {
                                                            backgroundColor: itemDetail.cateogry === item.categorieName ? "#192036" : 'rgb(0,0,0)'
                                                        },

                                                    }} key={index} value={item.categorieName}>{item.categorieName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                 
                                </FormControl>
                               
                                <Box marginY={"10px"}>
                                    <Button type="submit" variant='contained'>
                                        Add Item
                                    </Button>
                                </Box>
                            </form>
                        </Container>
                    </Box>
                </div >
            </div >
        </>
    )
}

export default AddCrops

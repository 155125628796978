import { Box, Button, TablePagination, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Swal from 'sweetalert2'
import SecondMenu from '../../Components/SecondMenu'
import SlideMenu from '../../Components/SlideMenu'
import firebase from '../../firebase'
import { Link } from 'react-router-dom';
/* eslint-disable */
function ManageCrop() {
    const [itemList, setItemList] = useState([]);
    const [cateList, setCateList] = useState([])
    const [search, setSearch] = useState('')
    const [category, setCategory] = useState('')
    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        const loadData = () => {
            firebase.database().ref('crops/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const itemsArry = [];

                for (let id in snapVal) {
                    itemsArry.push({ id, ...snapVal[id] })
                }
                setItemList(itemsArry)
                setFilterData(itemsArry)
                firebase.database().ref('categories/').on('value', (snapshot) => {
                    var snapVal = snapshot.val();
                    const fatched = [];

                    for (let id in snapVal) {
                        fatched.push({ id, ...snapVal[id] })
                    }
                    setCateList(fatched)
                })
            })

        }
        loadData()
    }, [])

    const searchFilter = (e) => {
        const text = e.target.value
        if (text) {
            const newData = itemList.filter(item =>
                item.cropName.toLowerCase().includes(text.toLowerCase())
            )
            setSearch(text)
            setFilterData(newData)
        }
        else {
            setSearch(text)
            setFilterData(itemList)
        }

    }
    return (
        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">
                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ m: 1, color: 'text.primary', }}
                                variant="h4"
                            >
                                Crops &amp; Items
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Link to="/add-crops">
                                    <Button variant='contained'>
                                        Add Crop or Item
                                    </Button>
                                </Link>
                            </Box>
                        </Box>

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive">
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Box>Sr No.</Box>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Box>Name</Box>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Box>Category</Box>
                                            </TableCell>
                                            <TableCell colSpan={2} align="left">
                                                <Box>Operations</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filterData.map((list, index) => (
                                            <ItemsTr key={index} category={category} cateList={cateList} list={list} index={index} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    )
}
function ItemsTr(props) {
    const deleteItem = () => {
        Swal.fire(
            'Do you really want to delelte these item?',
            '',
            'warning'
        ).then(() => {

            const ItemRef = firebase.database().ref('crops/').child(props.list.id);
            ItemRef.remove().then(() => {
                Swal.fire(
                    'Deleted Successfull',
                    "",
                    'success'
                )
            })



        })

    }
    const editName = () => {
        Swal.fire({
            title: 'Rename item',
            input: 'text',
            inputValue: props.list.cropName,
            inputAttributes: {
                autocapitalize: 'on'
            },
            showCancelButton: true,
            confirmButtonText: 'Look up',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                firebase.database().ref("crops").child(props.list.id).set({ ...props.list, cropName: login }).then(() => {
                    Swal.fire("Rename was succussfull", "", "success")
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    if (props.category === '') {
        return (
            <TableRow
                key={props.list.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="left">{props.index + 1}</TableCell>
                <TableCell align="left">{props.list.cropName}</TableCell>
                <TableCell align="left">{props.list.category}</TableCell>
                <TableCell key={props.index + 5} className="text-center">
                    <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                </TableCell>
                <TableCell>
                    <i onClick={editName} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i>
                </TableCell>
            </TableRow>
        )
    }
    else {
        return (
            props.category !== props.list.category ? '' :
                <TableRow
                    key={props.list.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell align="left">{props.index + 1}</TableCell>
                    <TableCell align="left">{props.list.cropName}</TableCell>
                    <TableCell align="left">{props.list.category}</TableCell>
                    <TableCell key={props.index + 5} className="text-center">
                        <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                    </TableCell>
                    <TableCell>
                        <i onClick={editName} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i>
                    </TableCell>
                </TableRow>
        )
    }
}
export default ManageCrop
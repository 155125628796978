import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GroupIcon from '@mui/icons-material/Group';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Box, FormControl, Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import CommonBlock from '../../Components/Dashboard/CommonBlock';
import { TrafficByDevice } from '../../Components/Dashboard/traffic';
import { IndianStates } from '../../Components/Geographical Data/Index';
import SecondMenu from '../../Components/SecondMenu';
import SlideMenu from '../../Components/SlideMenu';
import { UISelect } from '../../Components/UI';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import Counter from './Components/functions/Counters';
import Filter from './Components/functions/Filter';
import QuantityTotal from './Components/functions/QuantityTotal';
import ProductAnalytics from './Components/ProuductAnalytics';
/* eslint-disable */
function StateAnalytics() {
    const [{ crops, products }] = useDataLayerValue()
    const [productQuantity, setProductQuantity] = React.useState([])
    const [selectedState, setSelectedState] = React.useState(false)
    const handleStateChange = (e) => {
        var s_state = e.target.value
        const QuantityTotalObj = new QuantityTotal(new Filter(products).byState(s_state))
        setProductQuantity(QuantityTotalObj.productListWithTotalQuantity(crops))
        setSelectedState(s_state)
    }
    const StateSelect = () => {
        return (
            <FormControl sx={{ width: 100, mx: 4 }}>
                <UISelect required name="state" onChange={handleStateChange} value={selectedState} label="State" id="inputState">
                    <MenuItem value="">Select a State - राज्य चुनें</MenuItem>
                    {
                        IndianStates.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            )
                        })
                    }
                </UISelect>

            </FormControl>
        )
    }
    return (
        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories">
                <div className="main-child">
                    <SecondMenu title="Manage crops and items" url="/manage-crops-and-item" />
                    <div id="signInBtn"></div>
                    <Box sx={{ pb: 20 }} className="container smart-card">
                        <Typography
                            sx={{ m: 1, color: 'text.primary', }}
                            variant="h4"
                        >
                            Overview
                            <StateSelect />
                        </Typography>
                        {
                            selectedState ?
                                <StateAnalyticsComponent selectedState={selectedState} productQuantity={productQuantity} />
                                :
                                <h1 className="text-center text-white">
                                    No state is selected
                                </h1>
                        }
                    </Box>

                </div>
            </Box >
        </>
    )
}
const StateAnalyticsComponent = ({ selectedState, productQuantity }) => {
    const [{ itemToVerify, products, users }] = useDataLayerValue()
    return (
        <Grid
            container
            spacing={3}
        >
            <CommonBlock
                title="Items to verify"
                data={itemToVerify.length}
                Icon={CheckBoxIcon}
                bgColor="error.main"
            />
            <CommonBlock
                title="Listed Products"
                data={new Counter({ state: selectedState }, products).UsersOrCrops()}
                Icon={ViewListIcon}
                bgColor="secondary.main"
            />
            <CommonBlock
                title="Total Users"
                data={new Counter({ state: selectedState }, users).UsersOrCrops()}
                Icon={GroupIcon}
                bgColor="info.main"
            />
            <Grid item lg={8}
                sm={6}
                xl={3}
                xs={12}>

                <Box sx={{
                    widht: "100%",
                    backgroundColor: "neutral.700",
                    px: 3,
                    py: 2,
                    borderRadius: 1
                }}>
                    <ProductAnalytics productQuantity={productQuantity} />
                </Box>
            </Grid>
            <Grid item
                lg={4}
                sm={6}
                xl={3}
                xs={12}
            >
                <Box>
                    <TrafficByDevice sx={{ height: '100%', backgroundColor: "neutral.700" }} />
                </Box>
            </Grid>
        </Grid>
    )
}
export default StateAnalytics
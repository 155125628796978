import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Container, Grid, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Swal from 'sweetalert2';
import SecondMenu from '../Components/SecondMenu';
import SlideMenu from '../Components/SlideMenu';
import firebase from '../firebase';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import slugify from '../util/common/slugify';

function ManageOrders() {
    const [itemList, setItemList] = useState([]);
    const [cateList, setCateList] = useState([]);
    const [search, setSearch] = useState('');
    const [category, setCategory] = useState('');
    const [filterData, setFilterData] = useState([]);
    const [{ placed_orders }] = useDataLayerValue();

    // Function to filter search results
    const searchFilter = (e) => {
        const text = e.target.value;
        if (text) {
            const newData = itemList.filter(item =>
                item.name.toLowerCase().includes(text.toLowerCase())
            );
            setSearch(text);
            setFilterData(newData);
        } else {
            setSearch(text);
            setFilterData(itemList);
        }
    };

    return (
        <>
            <SlideMenu title="Edit Categories" url="/edit-categories" />
            <Box sx={{ bgcolor: 'background.default', display: "flex", flex: 1 }} className="main-display edit-categories">
                <div className="main-child">
                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}>
                            <Typography sx={{ m: 1, color: 'text.primary' }} variant="h4">Placed orders</Typography>
                        </Box>

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3 }} className="table-responsive">
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="right">Sr No.</TableCell>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell>Status Code</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Time Stamp</TableCell>
                                            <TableCell colSpan={3} className="text-center">Operation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {placed_orders.map((order, index) => (
                                            <ProductTr key={index} index={index} item={order} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    );
}

const ProductTr = ({ item: order, index }) => {
    const [open, setOpen] = useState(false);
    const [currentBuyer, setCurrentBuyer] = useState([]);
    const [curruntSeller, setCurruntSeller] = useState([]);

    useEffect(() => {
        if (order.buyerId) {
            firebase
                .database()
                .ref("users")
                .child(order.buyerId)
                .on("value", (snapshot) => {
                    setCurrentBuyer(snapshot.val());
                });
        } else {
            console.warn("Buyer ID is undefined");
        }
    
        if (order.sellerId) {
            firebase
                .database()
                .ref("users")
                .child(order.sellerId)
                .on("value", (snapshot) => {
                    setCurruntSeller(snapshot.val());
                });
        } else {
            console.warn("Seller ID is undefined");
        }
    }, [order.buyerId, order.sellerId]);
    

    // Function to delete an order
    const deleteItem = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it',
        }).then((result) => {
            if (result.isConfirmed) {
                if (order.id) {  // Check if order.id is defined and valid
                    firebase
                        .database()
                        .ref('orders/')
                        .child(order.id)
                        .remove()
                        .then(() => {
                            console.log('Order deleted successfully');
                        })
                        .catch((error) => {
                            console.error('Error deleting order:', error);
                        });
                } else {
                    console.warn('Order ID is undefined or invalid');
                }
            }
        });
    };
    

    return (
        <>
            <TableRow hover key={order.id}>
                <TableCell>
                    <Button onClick={() => setOpen(!open)}>
                        <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                    </Button>
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{order?.item?.name}</TableCell>
                <TableCell>{order?.orderStatus}</TableCell>
                <TableCell>{order?.quantity}</TableCell>
                <TableCell>{order?.item?.unit}</TableCell>
                <TableCell>&#8377; {order?.item?.price}</TableCell>
                <TableCell>{String(new Date(order?.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                <TableCell>
                    <Link to={{ pathname: '/edit-item-detail', state: { data: order } }}>
                        <i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i>
                    </Link>
                </TableCell>
                <TableCell>
                    <a target='_blank' href={`https://www.example.com/category/${slugify(order?.item?.category || "")}/${slugify(order?.item?.name || "")}/${order?.item?.itemId}`}>
                        <i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i>
                    </a>
                </TableCell>
                <TableCell>
                    <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                </TableCell>
            </TableRow>

            {open && (
                <TableRow>
                    <TableCell colSpan={11}>
                        <Container>
                            <Box paddingY="20px">
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography variant='h6' fontSize='17px' marginBottom="4px">Buyer Address</Typography>
                                        <Typography variant='p' sx={{ color: "text.primary" }} fontSize='14px'>
                                            {currentBuyer?.address_line_1}, <br />
                                            {currentBuyer?.address_line_2}, <br />
                                            {currentBuyer?.block}, {currentBuyer?.district},<br />
                                            {currentBuyer?.state}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h6' fontSize='17px' marginBottom="4px">Buyer Account Info</Typography>
                                        <Typography variant='p' sx={{ color: "text.primary" }} fontSize='14px'>
                                            <b>Name</b>: {currentBuyer?.name}<br />
                                            <b>Phone</b>: {currentBuyer?.phone}<br />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h6' fontSize='17px' marginBottom="4px">Seller Address</Typography>
                                        <Typography variant='p' sx={{ color: "text.primary" }} fontSize='14px'>
                                            {curruntSeller?.address_line_1}, <br />
                                            {curruntSeller?.address_line_2}, <br />
                                            {curruntSeller?.block}, {curruntSeller?.district},<br />
                                            {curruntSeller?.state}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h6' fontSize='17px' marginBottom="4px">Seller Account Info</Typography>
                                        <Typography variant='p' sx={{ color: "text.primary" }} fontSize='14px'>
                                            <b>Name</b>: {curruntSeller?.name}<br />
                                            <b>Phone</b>: {curruntSeller?.phone}<br />
                                            <b>Seller Type</b>: {curruntSeller?.userType}<br />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default ManageOrders;

import AgricultureIcon from '@mui/icons-material/Agriculture'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from "@mui/material/Typography"
import React, { useState } from 'react'
import MainCover from '../../Components/MainCover'
import { UISelect } from '../../Components/UI'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import Swal from 'sweetalert2'
import firebase from "../../firebase"
const CreateAccount = () => {
    const [screen, setScreen] = useState('default')
    const changeScreen = (screen) => {
        return () => {
            setScreen(screen)
        }
    }
    if (screen === "default") {
        return (
            <DefaultScreen changeScreen={changeScreen} />
        )
    }
    else if (screen === "farmer") {
        return (
            <FarmerFrom />
        )
    }
    else {
        return <CorporateForm />
    }
}
const DefaultScreen = ({ changeScreen }) => {
    return (
        <MainCover>
            <div className="container smart-card d-flex align-items-center" style={{ height: "calc(100vh - 64px)" }}>
                <Grid container gap={10}>
                    <Grid className='d-flex justify-content-center' item sm={5}>
                        <Box onClick={changeScreen("farmer")} sx={{
                            widht: "100%",
                            backgroundColor: "#2196F3",
                            px: 3,
                            py: 2,
                            borderRadius: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItem: "center",
                            transition: '0.4s',
                            cursor: "pointer",
                            ":hover": {
                                backgroundColor: "#2196F3a1",
                            }
                        }}>
                            <AgricultureIcon sx={{ fontSize: 200, color: "#fff" }} />
                        </Box>
                    </Grid>
                    <Grid className='d-flex justify-content-center' item sm={5}>
                        <Box onClick={changeScreen("corporate")} sx={{
                            widht: "100%",
                            backgroundColor: "#D14343",
                            px: 3,
                            py: 2,
                            borderRadius: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItem: "center",
                            transition: '0.4s',
                            cursor: "pointer",
                            ":hover": {
                                backgroundColor: "#D14343a1",
                            }
                        }}>
                            <CorporateFareIcon sx={{ fontSize: 200, color: "#fff" }} />
                        </Box>
                    </Grid>
                </Grid>
            </div>

        </MainCover>
    )
}
const FarmerFrom = () => {
    const [{ category, crops }] = useDataLayerValue()
    const [formData, setFormData] = useState({
        cropType: "",
        fieldSizeUnit: "",
        irrigation: "no",
        storage: "no",
        chemicalFertilizers: "no",
        soilTested: "no",
    })
    let value, name;

    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const handleCheckbox = (event) => {
        name = event.target.name;
        value = event.target.value;
        if (formData[name] === "yes") {
            setFormData({ ...formData, [name]: "no" });
        }
        else {
            setFormData({ ...formData, [name]: "yes" });
        }
    }
    const createAccount = (e) => {
        e.preventDefault()
        console.log(formData)
    }
    return (
        <MainCover>
            <div className="container smart-card">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{ m: 1, color: 'text.primary', }}
                        variant="h4"
                    >
                        Create Farmer Profile
                    </Typography>
                </Box>
                <div className="registration">
                    <form onSubmit={createAccount}>
                        <div id="wrapper">
                            <div className="crops">
                                <Typography
                                    sx={{ m: 1, color: 'text.light', }}
                                    variant="h6"
                                >
                                    Crops
                                </Typography>
                                <div className="d-flex justify-content-center">
                                    <FormControl sx={{ my: 2 }} fullWidth>
                                        <UISelect required name="cropType" value={formData.cropType} onChange={handleFormChanges} label="Crop Category" >
                                            {
                                                category.map((item, index) => {
                                                    if (
                                                        item.categorieName !== "Fertilizers & Pesticides - उर्वरक & कीटनाशक"
                                                        &&
                                                        item.categorieName !== "Farm Machinery - कृषि मशीनरी"
                                                        &&
                                                        item.categorieName !== "Irrigation Equipment - सिंचाई उपकरण"
                                                    ) {
                                                        return <MenuItem key={index} value={item.categorieName}>{item.categorieName}</MenuItem>
                                                    }
                                                })
                                            }
                                        </UISelect>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <FormControl sx={{ my: 2 }} fullWidth>
                                    <UISelect disabled={formData.cropType === ""} required name="cropName" onChange={handleFormChanges} label="Crop Name" >
                                        {
                                            crops.map((item, index) => {
                                                if (
                                                    item.category === formData.cropType
                                                ) {
                                                    return <MenuItem key={index} value={item.cropName}>{item.cropName}</MenuItem>
                                                }
                                            })
                                        }
                                    </UISelect>
                                </FormControl>
                            </div>
                            <Typography
                                sx={{ m: 1, color: 'text.light', }}
                                variant="h6"
                            >
                                Date of Sowing
                            </Typography>
                            <div className="d-flex justify-content-center">
                                <TextField label="  " fullWidth required onChange={handleFormChanges} type="date" name="dateOfSowing" placeholder="Date of sowing" />
                            </div>
                            <div className="row my-4">
                                <div className="col-lg-9">
                                    <div className="d-flex justify-content-center">
                                        <TextField fullWidth required onChange={handleFormChanges} type="number" name="fieldArea" label="Area of your farm" placeholder="Enter Area of your farm - कितने जमीन पर लगाए हैं?" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <FormControl fullWidth>
                                        <UISelect
                                            value={formData.fieldSizeUnit}
                                            label="Unit of Messurement"
                                            name="fieldSizeUnit"
                                            onChange={handleFormChanges}
                                        >
                                            <MenuItem value="hectare">Hectare - हैक्टर</MenuItem>
                                            <MenuItem value="acre">Acre - एकड़</MenuItem>
                                            <MenuItem value="bigha">Bigha - बीघा</MenuItem>
                                            <MenuItem value="katta">Katta - कट्टा</MenuItem>
                                        </UISelect>
                                    </FormControl>
                                </div>
                            </div>
                            <div>
                                <input onChange={handleCheckbox} type="checkbox" id="irrigation" name="irrigation" value={"yes"} />
                                <label htmlFor="irrigation">
                                    <Typography
                                        sx={{ m: 1, color: 'text.primary', }}
                                        variant="h6"
                                    >
                                        Irrigation Facility?
                                    </Typography>
                                </label>
                            </div>
                            <div>
                                <input onChange={handleCheckbox} type="checkbox" id="storage_facility" name="storage" value={"yes"} />
                                <label htmlFor="storage_facility">
                                    <Typography
                                        sx={{ m: 1, color: 'text.primary', }}
                                        variant="h6"
                                    >
                                        Storage Facility?
                                    </Typography>
                                </label>
                            </div>
                            <div>
                                <input onChange={handleCheckbox} type="checkbox" id="chemical_fertilizers" name="chemicalFertilizers" value={"yes"} />
                                <label htmlFor="chemical_fertilizers">
                                    <Typography
                                        sx={{ m: 1, color: 'text.primary', }}
                                        variant="h6"
                                    >
                                        Chemical fertilizers?
                                    </Typography>
                                </label>
                            </div>
                            <div>
                                <input onChange={handleCheckbox} type="checkbox" id="soilTested" name="soilTested" value={"yes"} />
                                <label htmlFor="soilTested">
                                    <Typography
                                        sx={{ m: 1, color: 'text.primary', }}
                                        variant="h6"
                                    >
                                        Soil tested?
                                    </Typography>
                                </label>
                            </div>
                            <br />
                            <Button variant='contained' color="error" id="submit" type="submit">
                                Create Account
                            </Button>

                        </div>
                    </form>
                </div>
            </div>
        </MainCover>
    )
}
const CorporateForm = () => {
    const [{ category, crops }] = useDataLayerValue()
    const [formData, setFormData] = useState({
        storage: "no",
    })
    let value, name;
    const showDetail = () => {
        firebase.database().ref("users").child(formData.userId).once("value").then((snapShot) => {
            var list = snapShot.val()
            delete list.farmerData
            delete list.product_for_sell
            delete list.item_rejected
            delete list.cart
            delete list.orders
            delete list.corporateData
            const theDetial = () => {
                var detail = ""
                Object.keys(list).map((col) => { detail = detail + `${col}: ${list[col]} <br>` })
                return detail
            }

            Swal.fire("The detail", `<div className="text-left">${theDetial()}</div>`, "info")
            console.log(list)
        })
    }
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const handleCheckbox = (event) => {
        name = event.target.name;
        value = event.target.value;
        if (formData[name] === "yes") {
            setFormData({ ...formData, [name]: "no" });
        }
        else {
            setFormData({ ...formData, [name]: "yes" });
        }
    }
    const createAccount = (e) => {
        e.preventDefault()
        firebase.database().ref('users').child(formData.userId).child("corporateData").set({ ...formData, lastUpdate: Date.now(), userId: null }).then(() => {
            Swal.fire("Profile Created Successfully!", '', 'success').then(() => { window.location.replace('/help-desk') })
        })
    }
    return (
        <MainCover>
            <div className="container smart-card">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{ m: 1, color: 'text.primary', }}
                        variant="h4"
                    >
                        Create Corporate's Profile
                    </Typography>
                </Box>
                <div className="registration">
                    <form onSubmit={createAccount}>
                        <div id="wrapper">
                            <Grid sx={{ my: 2 }} gap={4} container>
                                <Grid sm={8}>
                                    <TextField fullWidth required onChange={handleFormChanges} type="text" name="userId" label="User ID(UID)" />
                                </Grid>
                                <Grid sm={3}>
                                    <Button onClick={showDetail} fullWidth sx={{ height: "100%" }} color="success" variant='contained'>
                                        Verify
                                    </Button>
                                </Grid>
                            </Grid>
                            <TextField label="Company Name" fullWidth required onChange={handleFormChanges} type="text" name="name" />
                            <FormControl sx={{ mt: 3 }} fullWidth>
                                <UISelect required name="company_type" onChange={handleFormChanges} label="Company Type" >
                                    <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                                    <MenuItem value="Seller-Vendors-Distributor">Seller/Vendors/Distributor</MenuItem>
                                    <MenuItem value="FPO-SHG">FPO (Farmer Producer Orgnisation)/SHG (Self Help Group)</MenuItem>
                                </UISelect>
                            </FormControl>
                            <div className="my-4">
                                <TextField fullWidth required onChange={handleFormChanges} type="text" name="contact_person_name" label="Contact Person Name" />
                            </div>
                            <div className="my-4">
                                <TextField fullWidth required onChange={handleFormChanges} type="text" name="contact_person_email" label="Contact Person Email" />
                            </div>
                            <div className="my-4">
                                <TextField fullWidth required onChange={handleFormChanges} type="text" name="contact_person_phone" label="Contact Person Phone" />
                            </div>
                            <div className="my-4">
                                <TextField fullWidth required onChange={handleFormChanges} type="text" name="address" label="Company Address" />
                            </div>
                            <div className="my-4">
                                <TextField fullWidth onChange={handleFormChanges} type="text" name="gst" label="GST number" />
                            </div>
                            <div className="my-4">
                                <TextField fullWidth onChange={handleFormChanges} type="text" name="iso" label="ISO" />
                            </div>
                            <div className="my-4">
                                <TextField fullWidth onChange={handleFormChanges} type="text" name="website" placeholder='www.company.com (optional)' label="Company Website" />
                            </div>
                            <div>
                                <input onChange={handleCheckbox} type="checkbox" id="storage" name="storage" value={"yes"} />
                                <label htmlFor="storage">
                                    <Typography
                                        sx={{ m: 1, color: 'text.primary', }}
                                        variant="h6"
                                    >
                                        Storage Facility?
                                    </Typography>
                                </label>
                            </div>

                            <br />
                            <Button variant='contained' color="error" id="submit" type="submit">
                                Create Account
                            </Button>

                        </div>
                    </form>
                </div>
            </div>
        </MainCover>
    )
}

export default CreateAccount
class Counter {
    constructor({ state = false, district = false, block = false }, list) {
        this.state = state
        this.district = district
        this.block = block
        this.list = list
    }
    UsersOrCrops() {
        if (this.state) {
            var count = 0
            this.list.map((item) => {
                if (item.state === this.state) {
                    count++
                }
            })
            return (count)
        }
        else if (this.district) {
            var count = 0
            this.list.map((item) => {
                if (item.district === this.district) {
                    count++
                }
            })
            return (count)
        }
        else if (this.block) {
            var count = 0
            this.list.map((item) => {
                if (item.block === this.block) {
                    count++
                }
            })
            return (count)
        }
    }


}
export default Counter
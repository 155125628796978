import { Send as SendIcon } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import firebase from "../../firebase"
import React, { useState } from 'react';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '70vh'
    },
    headBG: {
        backgroundColor: '#7582eb'
    },
    borderRight500: {
        borderRight: '1px solid #7582eb'
    },
    messageArea: {
        height: '65vh',
        overflowY: 'hidden'
    }
});
const getTime = (timestamp) => {
    const time = new Date(timestamp);
    const hour = time.getHours();
    const minute = time.getMinutes();
    const calculatedHour = hour - 12;
    const meridian = hour > 12 ? "AM" : "PM";
    const calculatedMinute = minute < 10 ? `0${minute}` : minute;
    const formattedTime = `${calculatedHour}:${calculatedMinute} ${meridian}`;

    return formattedTime;
}
const Chats = ({ currentUser }) => {
    const [message, setMessage] = useState("")
    const classes = useStyles();
    const sendMessage = () => {
        firebase.database().ref(`users/${currentUser.id}/direct_messages`).push(
            {
                message,
                timestamp: Date.now(),
                sender: "expert",
                read: false
            }).then(() => {
                const scrollHeight = document.querySelector(".chat-sec").scrollHeight + 10000;
                document.querySelector(".chat-sec").scrollTo(0, scrollHeight)
            })

    }
    return (
        <Grid item xs={9}>
            <List>
                <ListItem button key="RemySharp">
                    <ListItemIcon>
                        <Avatar>{currentUser?.name[0] || ""}</Avatar>
                    </ListItemIcon>
                    <div className='text-light'>
                        <h4 style={{ fontSize: "18px" }}>{currentUser?.name || ""}</h4>
                        <h6 style={{ fontSize: "14px", fontWeight: "normal" }}>{currentUser?.phone || ""}</h6>
                    </div>
                </ListItem>
            </List>
            <Divider />
            <List className={classes.messageArea}>
                <div className="chat-sec" >

                    {
                        currentUser.direct_messages.map((item, index) => {
                            return (
                                <div key={index} className={item.sender !== "expert" ? "sender" : "reciver"}>
                                    <div className="box">
                                        {item.image &&
                                            <div className="image">
                                                <img src={item.image} className='w-full' alt="image" />
                                            </div>
                                        }
                                        <p>
                                            {item.message}
                                        </p>
                                        <div className="time">
                                            {getTime(item.timestamp)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }
                </div>
            </List>
            <Divider />
            <Grid container style={{ padding: '20px' }}>
                <Grid item xs={11}>
                    <TextField value={message} onChange={(e) => setMessage(e.target.value)} id="outlined-basic-email" label="Type Something" fullWidth />
                </Grid>
                <Grid xs={1} align="right">
                    <Fab onClick={sendMessage} color="primary" aria-label="add"><SendIcon /></Fab>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Chats
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography"
import React, { useState } from 'react'
import MainCover from '../../Components/MainCover'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import StateDisctrict from '../../Components/StateDisctrict'
import { UISelect } from '../../Components/UI'
import { FormControl, MenuItem } from '@mui/material'
import firebase from "../../firebase"
import Swal from 'sweetalert2'
const RegisterUser = () => {
    const [formData, setFormData] = useState({
        state: "",
        userType: "",
        district: ""
    })
    let value, name;

    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const registerUser = (e) => {
        e.preventDefault()
        firebase.database().ref('users').child(formData.userId).set({ ...formData, timeStamp: Date.now() }).then(() => {
            Swal.fire("User Registed Successfully!", '', 'success').then(() => { window.location.replace('/help-desk') })
        })
    }
    return (
        <MainCover>
            <div className="container smart-card">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{ m: 1, color: 'text.primary', }}
                        variant="h4"
                    >
                        Register User
                    </Typography>
                </Box>
                <form onSubmit={registerUser}>

                    <TextField
                        autoComplete="off"
                        required
                        onChange={handleFormChanges}
                        fullWidth
                        sx={{ my: 2 }}
                        type='text'
                        name="userId"
                        label="UID of user"
                        placeholder='Enter UID of your'
                    />
                    <TextField
                        autoComplete="off"
                        required
                        onChange={handleFormChanges}
                        fullWidth
                        sx={{ my: 2 }}
                        type="text"
                        name="phone"
                        label="User's Phone number"
                        id="name"
                        placeholder="+91XXXXXXXXXX"
                    />
                    <TextField
                        autoComplete="off"
                        required
                        onChange={handleFormChanges}
                        fullWidth
                        sx={{ my: 2 }}
                        type="text"
                        name="name"
                        label="User's Name"
                        id="name"
                        placeholder="User's Name"
                    />
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <UISelect
                            required
                            name="userType"
                            label="Select User type"
                            id="inputDistrict"
                            value={formData.userType}
                            onChange={handleFormChanges}
                        >
                            <MenuItem value="farmer">I want to Sell and Buy (Farmer - किसान)</MenuItem>
                            <MenuItem value="consumer">I want to Buy (Consumer)</MenuItem>
                            <MenuItem value="corporate">I want to Sell and Buy (Seller/ Manufacturer)</MenuItem>
                        </UISelect>
                    </FormControl>

                    <TextField
                        autoComplete="off"
                        required
                        onChange={handleFormChanges}
                        fullWidth
                        sx={{ my: 2 }}
                        type="text"
                        name="address"
                        label="Address/Landmark"
                        id="address"
                        placeholder="Address/Landmark"
                    />
                    <TextField
                        autoComplete="off" onChange={handleFormChanges}
                        fullWidth
                        sx={{ my: 2 }}
                        type="email"
                        name="email"
                        label="Email (optional)"
                        id="email"
                        placeholder="Email (optional)"
                    />
                    <StateDisctrict fullWidth={true} setFormData={setFormData} formData={formData} handleFormChanges={handleFormChanges} />
                    <Button
                        sx={{ my: 2 }}
                        variant="contained"
                        type="submit"
                    >
                        Create User
                    </Button>

                </form>
            </div>
        </MainCover>
    )
}

export default RegisterUser
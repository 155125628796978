import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography"
import React from 'react'
import HelpBlock from '../../Components/HelpDesk/HelpBlock'
import MainCover from '../../Components/MainCover'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
const HelpDesk = () => {


    return (
        <MainCover>
            <div className="container smart-card">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{ m: 1, color: 'text.primary', }}
                        variant="h4"
                    >
                        Help Desk
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={3}
                >
                    <HelpBlock
                        Icon={PersonAddIcon}
                        btnTxt={"Register User"}
                        title="Register a User"
                        link={`/help-desk/register-user`}
                        bg="#D14343"
                        color="error"
                    />
                    <HelpBlock
                        Icon={ContactMailIcon}
                        btnTxt={"Create Account"}
                        title="Cerate an seller account"
                        link={`/help-desk/create-account`}
                        bg="#2196F3"
                        color="info"
                    />
                    <HelpBlock
                        Icon={AddBusinessIcon}
                        btnTxt={"Add Products"}
                        title="Add Products to Seller's store"
                        link={`/help-desk/add-product`}
                        bg="#10B981"
                        color="success"
                    />
                </Grid>

                #10B981

            </div>
        </MainCover>
    )
}

export default HelpDesk
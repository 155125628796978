import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import MainCover from '../../Components/MainCover';
import ChatList from '../../Components/chats/ChatList';
import fetchChats from '../../util/chat/fetchChats';
import Chats from '../../Components/chats/Chats';
import firebase from "../../firebase"
const Chat = () => {
    const [curruntUser, setCurrentUser] = useState({
        name: "",
        phone: "",
        direct_messages: []
    });
    useEffect(() => {
        async function getData() {
            firebase.database().ref('users/').on('value', (snapshot) => {
                const rawUserData = []
                var snapVal = snapshot.val();
                for (let id in snapVal) {
                    var currentUser = snapVal[id]
                    if (currentUser.direct_messages) {
                        const direct_messages = Object.values(currentUser.direct_messages)
                        rawUserData.push({ id: id, name: currentUser.name, phone: currentUser.phone, direct_messages })
                    }
                }
                rawUserData.sort((a, b) => {
                    return b.direct_messages[b.direct_messages.length - 1].timestamp - a.direct_messages[a.direct_messages.length - 1].timestamp
                })
                setCurrentUser(rawUserData[0])
                const scrollHeight = document.querySelector(".chat-sec").scrollHeight + 10000;
                document.querySelector(".chat-sec").scrollTo(0, scrollHeight)
            })
        }
        getData()

    }, [])
    return (
        <MainCover>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={false}
            >
            </Backdrop>
            <div className="smart-card">
                <div>
                    <Grid container>
                        <ChatList setCurrentUser={setCurrentUser} />
                        {curruntUser.direct_messages.length && <Chats currentUser={curruntUser} />}
                    </Grid>
                </div>
            </div>
        </MainCover >
    )
}
export default Chat
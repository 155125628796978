
class QuantityTotal {
    constructor(productList) {
        this.productList = productList
    }
    QuantityTotaler(ProductName) {
        const filteredArray = this.productList.filter((x) => {
            return x.name === ProductName.cropName && x.category === ProductName.category
        })
        var totalItem = 0;
        var itemCount = 0
        filteredArray.map((x) => {
            totalItem += parseInt(x.quantity)
            itemCount++
        })

        return { totalItem, itemCount }

    }
    productListWithTotalQuantity(cropsList) {
        const cropsWithQuantity = []
        cropsList.map((item) => {
            const MyFunction = this.QuantityTotaler(item)
            if (MyFunction.totalItem > 0) {
                cropsWithQuantity.push({ quantity: MyFunction.totalItem, name: item.cropName, categroy: item.category, itemCount: MyFunction.itemCount })
            }
        })
        return cropsWithQuantity
    }
}
export default QuantityTotal
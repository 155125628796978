import { Box, Button, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SecondMenu from '../Components/SecondMenu';
import SlideMenu from '../Components/SlideMenu';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import firebase from '../firebase';
/* eslint-disable */
function ExpertTalk() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const loadData = () => {
            async function getData() {
                firebase.database().ref('expert_chats/').on('value', (snapshot) => {
                    const rawUserData = []
                    var snapVal = snapshot.val();
                    for (let id in snapVal) {
                        rawUserData.push({ ...snapVal[id], id: id })
                    }
                    setData(rawUserData.reverse())
                })


            }
            getData()
        }
        loadData()
    }, [])
    const deleteItem = (id, imgUrl) => {
        const urlToName = (url) => {

            return String(url.split("/")[7].replace("expert_chats%2F", "").split("?alt")[0].replace("%5B", "[").replace("%5D", "]"))
        }
        Swal.fire(
            'Do you really want to delelte category?',
            '',
            'warning'
        ).then((event) => {
            if (event.isConfirmed) {

                const ItemRef = firebase.database().ref('expert_chats/').child(id);
                ItemRef.remove().then(() => {
                    firebase.storage().ref("expert_chats").child(urlToName(imgUrl)).delete().then(
                        () => {
                            Swal.fire("Item Deleted Successfully", "", "success")
                        }
                    )


                })
            }
        })

    }
    const reply = async ({ id, sellerUID,  message}) => {
        const { value: enterdMessage } = await Swal.fire({
            title: 'Reply to the user',
            input: 'text',
            inputLabel: 'message for seller',
            // inputValue: inputValue,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!'
                }
            }
        })

        if (enterdMessage) {
            firebase.database().ref("users").child(sellerUID).child("notifications").push({
                type: "experts-reply",
                reply: enterdMessage,
                timestamp: Date.now(),
                replyedTo: message  
            }).then(()=>{
                Swal.fire("Message sent to the user", "Message has been sent to user successfully", "success")
            })
        }
    }
    return (

        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">
                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ m: 1, color: 'text.primary', }}
                                variant="h4"
                            >
                                Expert's Messages
                            </Typography>
                        </Box>

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive">
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Img</TableCell>
                                            <TableCell>Mobile No.</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell colSpan={2} className="text-center">Opration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((list, index) => (
                                            <TableRow key={index + 1}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{list.userName}</TableCell>
                                                <TableCell><img style={{ width: '50%' }} src={list.imgUrl} /></TableCell>
                                                <TableCell>{list.userPhone}</TableCell>
                                                <TableCell>{list.message}</TableCell>
                                                <TableCell onClick={() => deleteItem(list.id, list.imgUrl)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-trash text-danger"></i></TableCell>
                                                <TableCell onClick={() => reply(list)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-edit text-success"></i></TableCell>
                                            </TableRow>

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </div>
                </div>
            </Box>
        </>
    )
}

export default ExpertTalk
import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
// import faker from 'faker';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                // fontColor: '#fff',
                color: "#fff",
            }
        },
        title: {
            display: true,
            text: 'Visitors',
            color: "#fff"
        },
    },
    bezierCurve: false

};


export default function Dhiraj() {
    const [data, setData] = useState(null)
    useEffect(() => {
     

        const labels = []
        const users = []
        const load = async () => {
            const response = await fetch(
                `https://tiny-lime-clownfish-kit.cyclic.app/api/vistors-by-city?apiKey=e2imDucdH327btQTykL5Z47VNOVVqoc7`,
                // { mode: 'no-cors' }

            )

            var json = await response.json()
            const rowData = []
            json.rows.map((item) => {
                if (parseInt(item.metricValues[0].value) > 2) {
                    rowData.push([parseInt(item.dimensionValues[0].value), parseInt(item.metricValues[0].value)])
                }
            })
            function sortByDate(a, b) {
                return a[0] - b[0];
            }

            rowData.sort(sortByDate);
            const itemInserter = (array, index, element) => {
                for (let i = array.length; i > index; i--) {

                    //shift the elements that are greater than index
                    array[i] = array[i - 1];
                }

                // insert element at given index
                array[index] = element;
                return array
            }
            const dateFormet = (date) => {
                var step1 = itemInserter(JSON.stringify(date).split(""), 4, "-")
                var step2 = itemInserter(step1, 7, "-")
                return step2.join("")
            }
            rowData.map((item) => {

                labels.push(dateFormet(item[0]))
                users.push(item[1])
            })
            var processedData = {
                labels,
                datasets: [
                    {
                        label: 'Users',
                        borderColor: 'rgb(47 62 177)',
                        backgroundColor: 'rgba(47, 62, 177, 0.5)',
                        data: users,
                        color: "#fff",
                        backgroundColor: 'rgb(47 62 177)',
                    },
                ],
            }
            setData(processedData)

        }
        load()
    }, [])
    if (data === null) {
        return <h1 style={{ color: "#fff" }}>Loading</h1>
    }
    else {
        return <Line height={window.innerWidth < 600 ? "400px" : "auto"} options={options} data={data} />;
    }
}

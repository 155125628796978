import { Box } from '@mui/material';
import React from 'react';
import SecondMenu from './SecondMenu';
import SlideMenu from './SlideMenu';
/* eslint-disable */
function MainCover({ children }) {

    return (
        <>
            <SlideMenu />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">
                    <SecondMenu title="Manage orders" />
                    {children}
                </div>
            </Box>
        </>
    )
}

export default MainCover
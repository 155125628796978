import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import { useEffect, useState } from 'react';
const ProductAnalytics = ({ productQuantity }) => {
    const [{ products, crops }] = useDataLayerValue()
    return (
        <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
            <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "#1f2937" }}>
                    <TableRow>
                        <TableCell align="left">
                            Sr No.
                        </TableCell>
                        <TableCell align="left">
                            Product Name
                        </TableCell>
                        <TableCell align="left">
                            Product Quantity
                        </TableCell>
                        <TableCell align="left">
                            Unit
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        productQuantity.map((item, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">{item.name} ({item.itemCount})</TableCell>
                                    <TableCell align="left">{item.quantity}</TableCell>
                                    <TableCell align="left">{item.categroy.includes("Farm Machinery") ? "Unit" : "KG"}</TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default ProductAnalytics
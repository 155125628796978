import Swal from 'sweetalert2'
import firebase from "../../firebase"


const showUserDetail = ({ userId, setUser = null }) => {
    return () => {
        firebase.database().ref("users").child(userId).once("value").then((snapShot) => {
            if (setUser) {
                setUser(snapShot.val())
            }
            var list = snapShot.val()
            delete list.farmerData
            delete list.product_for_sell
            delete list.item_rejected
            delete list.cart
            delete list.orders
            delete list.corporateData
            const theDetial = () => {
                var detail = ""
                Object.keys(list).map((col) => { detail = detail + `${col}: ${list[col]} <br>` })
                return detail
            }

            Swal.fire("The detail", `<div className="text-left">${theDetial()}</div>`, "info")
            console.log(list)
        })
    }
}




export default showUserDetail
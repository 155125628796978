import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import firebase from "../../firebase"
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headBG: {
    backgroundColor: '#7582eb'
  },
  borderRight500: {
    borderRight: '1px solid #fff'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});
const ChatList = ({ setCurrentUser }) => {
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function getData() {
      firebase.database().ref('users/').on('value', (snapshot) => {
        const rawUserData = []
        var snapVal = snapshot.val();
        for (let id in snapVal) {
          var currentUser = snapVal[id]
          if (currentUser.direct_messages) {
            const direct_messages = Object.values(currentUser.direct_messages)
            rawUserData.push({ id: id, name: currentUser.name, phone: currentUser.phone, direct_messages })
          }
        }
        rawUserData.sort((a, b) => {
          return b.direct_messages[b.direct_messages.length - 1].timestamp - a.direct_messages[a.direct_messages.length - 1].timestamp
        })
        setData(rawUserData)
      })
    }
    getData()
  }, [])
  return (
    <Grid item xs={3} className={classes.borderRight500}>
      <Grid item xs={12} style={{ padding: '10px' }}>
        <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
      </Grid>
      <Divider />
      <List>
        {
          data?.map((item, index) => {
            return <ListItem key={index} button>
              <ListItemIcon>
                <Avatar>{item.name[0]}</Avatar>
              </ListItemIcon>
              <div onClick={() => setCurrentUser(item)} className='text-light d-flex align-items-center justify-content-between w-100'>
                <div>
                  <h4 style={{ fontSize: "14px" }}>{item.name}</h4>
                  <h6 style={{ fontSize: "12px", fontWeight: "normal" }}>{item.phone}</h6>
                </div>
                {item.direct_messages.some((item) => item.read === false && item.sender !== "expert") && <Badge color="secondary" badgeContent="" variant="dot"></Badge>}
              </div>
            </ListItem>
          })
        }
      </List>
    </Grid>
  )
}

export default ChatList
import React, { useEffect, useState } from 'react'
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import Graphs from './Graphs';

function RegistedUser() {

    return (
        <Graphs />

    )
}

export default RegistedUser
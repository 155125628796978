import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import AddCrops from './Product/AddCrops';
import ContactMails from './ContactMail';
import Corporate from './Users/Corporate';
import EditCategories from './Product/EditCategories';
import EditItemDetail from './Product/EditItemDetail';
import Farmer from './Users/Farmer';
import ItemsToVerify from './Product/ItemToVerify';
import Login from './Login';
import ManageCrop from './Product/ManageCrop';
import ManageItems from './Product/ManageItems';
import ManageOrders from './ManageOrder';
import UserData from './Users/UserData';
import DashBoard from '.';
import firebase from '../firebase';
import Dhiraj from './Dhiraj';
import EnquiryMails from './EnquiryMails';
import ExpertTalk from './ExpertTalk';
import Analytics from './Analytics';
import StateAnalytics from "./Analytics/StateAnalytics"
import DistrictAnalytics from './Analytics/DistrictAnalytics';
import BlockAnalytics from './Analytics/BlockAnalytics ';
import HelpDesk from './HelpDesk';
import RegisterUser from './HelpDesk/RegisterUser';
import CreateAccount from './HelpDesk/CreateAccount';
import AddProduct from './HelpDesk/AddProduct';
import Chat from './Chat';
// import Login from './Login';
function AdminPanel() {
    const [{ drawer }, dispatch] = useDataLayerValue()
    useEffect(() => {
        const loadData = () => {

            firebase.database().ref('item-to-verify/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const itemsArry = [];

                for (let id in snapVal) {
                    itemsArry.push({ id, ...snapVal[id] })
                }
                dispatch({
                    type: "SET_ITEM_TO_VERIFY",
                    data: itemsArry
                })


            })
            firebase.database().ref('items/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const itemsArry = [];

                for (let id in snapVal) {
                    itemsArry.push({ id, ...snapVal[id] })
                }
                dispatch({
                    type: "SET_PRODUCTS",
                    data: itemsArry
                })
            })
            firebase.database().ref('categories/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const fatched = [];

                for (let id in snapVal) {
                    fatched.push({ id, ...snapVal[id] })
                }
                dispatch({
                    type: "SET_CATE_LIST",
                    data: fatched
                })
            })
            firebase.database().ref('crops/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const fatched = [];

                for (let id in snapVal) {
                    fatched.push({ id, ...snapVal[id] })
                }
                dispatch({
                    type: "SET_CROPS",
                    data: fatched
                })
            })
            firebase.database().ref('orders/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const fatched = [];

                for (let id in snapVal) {
                    fatched.push({ id, ...snapVal[id] })
                }
                fatched.sort((a,b)=>b.timeStamp-a.timeStamp)
                dispatch({
                    type: "SET_PLACED_ORDERS",
                    data: fatched
                })
            })
            firebase.database().ref('users/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const userdata = [];

                for (let id in snapVal) {
                    userdata.push({ id, ...snapVal[id] })
                }

                dispatch({
                    type: "SET_USERS",
                    data: userdata
                })
                var farmers = 0
                var consumers = 0
                var corporates = 0
                userdata.map((item) => {
                    var userType = item.userType

                    if (userType === "farmer") {
                        farmers += 1
                    }
                    if (userType === "consumer") {
                        consumers += 1
                    }
                    if (userType === "corporate") {
                        corporates += 1
                    }

                })
                dispatch({
                    type: "SET_LENGTHS",
                    data: { farmers, corporates, consumers }
                })
            })
        }
        loadData()
    }, [])
    return (
        <>
            <div
                className={`main`}
            >
                <Switch>
                    <Route exact path="/" component={DashBoard} />
                    <Route exact path="/add-crops" component={AddCrops} />
                    <Route exact path="/edit-item-detail" component={EditItemDetail} />
                    <Route exact path="/products/categories" component={EditCategories} />
                    <Route exact path="/products/verify" component={ItemsToVerify} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/products/crops-and-item" component={ManageCrop} />
                    <Route exact path="/orders" component={ManageOrders} />
                    <Route exact path="/products/all" component={ManageItems} />
                    <Route exact path="/mails" component={ContactMails} />
                    <Route exact path="/enquiry" component={EnquiryMails} />
                    <Route exact path="/dhiraj" component={Dhiraj} />
                    <Route exact path="/users/all" component={UserData} />
                    <Route exact path="/users/farmers" component={Farmer} />
                    <Route exact path="/users/corporates" component={Corporate} />
                    <Route exact path="/experts-mail" component={ExpertTalk} />
                    <Route exact path="/analytics" component={Analytics} />
                    <Route exact path="/analytics/state" component={StateAnalytics} />
                    <Route exact path="/analytics/district" component={DistrictAnalytics} />
                    <Route exact path="/analytics/block" component={BlockAnalytics} />
                    <Route exact path="/analytics" component={Analytics} />
                    <Route exact path="/help-desk" component={HelpDesk} />
                    <Route exact path="/help-desk/register-user" component={RegisterUser} />
                    <Route exact path="/help-desk/create-account" component={CreateAccount} />
                    <Route exact path="/help-desk/add-product" component={AddProduct} />
                    <Route exact path="/chat" component={Chat} />
                </Switch>
            </div>
        </>
    )
}

export default AdminPanel
